import React from "react";

export function FAQ() {
  return (
    <React.Fragment>
      <div className="bloc bg-background-1 d-bloc" id="header-bloc">
        <div className="container bloc-md">
          <div className="row">
            <div className="align-self-center col-lg-12 col-md-12">
              <h1 className="h2-style text-md-start text-sm-start text-start pb-0">
                FAQ
              </h1>
              <p className="text-sm-start text-start header-p">
                If you have questions, we have answers
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bloc l-bloc" id="bloc-2">
        <div className="container bloc-lg">
          <div className="row">
            <div className="col-lg-12 pb-4">
              <h3 className="mb-4 h3">
                How do I connect my account with Thirdverse?
              </h3>
              <p className="p">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim.
              </p>
            </div>
            <div className="col-lg-12 pb-4">
              <h3 className="mb-4 h3">Another question goes here?</h3>
              <p className="p">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim.
              </p>
            </div>
            <div className="col-lg-12 pb-4">
              <h3 className="mb-4 h3">Why is X8 such an awesome game?</h3>
              <p className="p">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim.
              </p>
            </div>
            <div className="col">
              <h3 className="mb-4 h3">
                Cool, I think that’s enough questions?
              </h3>
              <p className="p">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
