import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuthData } from "./../../authDataContext";
import { CookieConsent } from "./../CookieConsent";
import { Header } from "./../Header";
import { Footer } from "./../Footer";
import { SignIn } from "./../../pages/SignIn";
import { SignOut } from "./../../pages/SignOut";
import { MyAccount } from "./../../pages/MyAccount";
import { FAQ } from "./../../pages/FAQ";

export function App() {
  const { setData } = useAuthData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const resp = await axios.get(
          process.env.REACT_APP_API_URL + "/api/account",
          { withCredentials: true }
        );
        setData(resp.data);

        if (
          location.pathname === "/" &&
          (resp.data.externals.length > 0 || resp.data.games.length > 0)
        ) {
          navigate("/account");
        }
      } catch (err) {
        setData({ externals: [], games: [] });
        if (location.pathname === "/account") {
          navigate("/signout");
        } else if (location.pathname !== "/signin") {
          navigate("/signin");
        }
      }
    };

    checkAuth();
  }, [navigate, location.pathname, setData]);

  return (
    <React.Fragment>
      <div id="page-loading-blocs-notifaction" className="page-preloader"></div>
      <div className="page-container">
        <Header />
        <Routes>
          <Route path="/account" element={<MyAccount />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
        </Routes>
        <Footer />
      </div>
      <CookieConsent />
    </React.Fragment>
  );
}
