import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export function SignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      try {
        await axios.get(process.env.REACT_APP_API_URL + "/signout", {
          withCredentials: true,
        });
        console.log("signout success"); // remove me
        navigate("/");
      } catch (err) {
        console.error("signout failure", err); // remove me
        navigate("/");
      }
    };

    logout();
  }, [navigate]);

  return null;
}
