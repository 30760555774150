import React from "react";
import { LoginButton } from "../../components/SSO";

const HeaderBloc = () => (
  <div className="bloc bg-background-1 d-bloc" id="header-bloc">
    <div className="container bloc-lg">
      <div className="row">
        <div className="align-self-center col-lg-12 col-md-12">
          <h1 className="h2-style text-md-start text-sm-start text-start pb-0">
            Sign In to Thirdverse
          </h1>
          {/*
          <p className="text-sm-start text-start header-p">
            Description of what the user can do with their Thirdverse account
          </p>
          <a
            href="#sign-in"
            className="btn float-sm-none btn-c-521 btn-rd ps-lg-4 pe-lg-4"
          >
            Get Started
          </a>
          */}
        </div>
      </div>
    </div>
  </div>
);

const SignInBloc = () => {
  const ssoServiceCodes = ["meta", "steam", "psn"];

  return (
    <div className="bloc l-bloc" id="sign-in">
      <div className="container bloc-lg">
        <div className="row">
          <div className="col">
            <h2 className="mb-4 tc-3647">
              Sign in using one of the following services
            </h2>
          </div>
        </div>
        <div className="row">
          {ssoServiceCodes.map((code) => (
            <div
              key={code}
              className="text-start col-sm-6 col-md-6 col-lg-3 col-6 text-lg-start text-sm-start text-md-start"
            >
              <LoginButton code={code}>
                <img
                  src="img/lazyload-ph.png"
                  data-src={`${process.env.PUBLIC_URL}/images/referral-logo-${code}.svg`}
                  className="img-fluid mx-auto d-block referral-logo mb-4 lazyload"
                  alt={`referral logo-${code}`}
                  width="558"
                  height="558"
                />
              </LoginButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export function SignIn() {
  return (
    <React.Fragment>
      <HeaderBloc />
      <SignInBloc />
    </React.Fragment>
  );
}
