import React, { createContext, useContext, useState } from "react";

const AuthDataContext = createContext();

export const AuthDataProvider = ({ children }) => {
  const [data, setData] = useState({ externals: [], games: [] });
  const isAuthenticated = data.externals.length > 0 || data.games.length > 0;

  return (
    <AuthDataContext.Provider value={{ data, setData, isAuthenticated }}>
      {children}
    </AuthDataContext.Provider>
  );
};

export const useAuthData = () => useContext(AuthDataContext);
