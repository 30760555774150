import React, { useState, useEffect } from "react";

export function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  const handleConsent = () => {
    localStorage.setItem("hasConsentedToCookies", true);
    setIsVisible(false);
  };

  useEffect(() => {
    const hasConsented = localStorage.getItem("hasConsentedToCookies");
    if (!hasConsented) {
      setIsVisible(true);
    }
  }, []);

  if (!isVisible) return null;

  return (
    <div
      className="position-fixed bottom-0 end-0 w-100 bgc-3968 p-4"
      style={{ zIndex: 1000 }}
    >
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col">
            <p className="m-0">
              Our website uses cookies for Single Sign-On (SSO) related
              functionalities and to ensure proper functioning.
              <br />
              By continuing to use this site, you consent to our use of cookies.
              Please enable cookies in your browser settings to continue.
            </p>
          </div>
          <div className="col-auto">
            <button
              className="btn float-sm-none btn-c-521 btn-rd ps-lg-4 pe-lg-4"
              style={{ width: 100 }}
              onClick={handleConsent}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
