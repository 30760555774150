import React from "react";

export const Modal = ({
  title,
  content,
  ng = "Close",
  ok = "Unlink",
  onClose,
  onConfirm,
}) => (
  <React.Fragment>
    <div className="modal fade show d-block" tabIndex="-1">
      <div className="modal-dialog  modal-dialog-centered">
        <div className="modal-content">
          <div className="bgc-3968 modal-header">
            <h3 className="mg-clear tc-3647">{title}</h3>
          </div>
          <div className="modal-body">
            <p>{content}</p>
          </div>
          <div className="bgc-3968 modal-footer">
            <button
              onClick={onClose}
              className="btn float-sm-none btn-c-521 btn-rd ps-lg-4 pe-lg-4 button-redeem"
            >
              {ng}
            </button>
            {onConfirm && (
              <button
                onClick={onConfirm}
                className="btn float-sm-none btn-rd ps-lg-4 pe-lg-4 btn-danger"
              >
                {ok}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show"></div>
  </React.Fragment>
);
