import React, { useState } from "react";
import axios from "axios";
import { Modal } from "../../components/Modal";
import { useAuthData } from "./../../authDataContext";
import { LinkButton } from "../../components/SSO";

const HeaderBloc = () => (
  <div className="bloc bg-background-1 l-bloc" id="header-bloc">
    <div className="container bloc-md">
      <div className="row">
        <div className="align-self-center col-lg-12 col-md-12">
          <h1 className="h2-style text-md-start text-sm-start text-start pb-0">
            My Account
          </h1>
        </div>
      </div>
    </div>
  </div>
);

const LinkBloc = ({ externals }) => {
  const linkedLoginAccountsCount = externals.filter(({ enabled, login }) => enabled && login).length;
  const linkedAdditionalAccountsCount = externals.filter(({ enabled, login }) => enabled && !login).length;
  return (
    <div className="bloc l-bloc" id="bloc-2">
      <div className="container bloc-lg">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="mb-4 tc-3647">
              Link or unlink your account with one of the following services
            </h2>
          </div>
          <div className="col">
            {externals.map((external) => (
              <div key={external.code} className="row mb-4">
                <div className="col-lg-2 col-4 col-md-3 text-md-start text-sm-start">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/lazyload-ph.png`}
                    data-src={`${process.env.PUBLIC_URL}/images/referral-logo-${external.code}.svg`}
                    className="img-fluid mx-auto d-block referral-logo lazyload"
                    alt={`referral logo-${external.code}`}
                    width="267"
                    height="267"
                  />
                </div>
                <div className="col align-self-center">
                  <LinkButton
                    {...external}
                    linkedLoginAccountsCount={linkedLoginAccountsCount}
                    linkedAdditionalAccountsCount={linkedAdditionalAccountsCount}
                  />
                  {external.enabled && (
                    <p className="mt-lg-2 mb-lg-0 mb-0 mt-2 username-tag">
                      Username:{" "}
                      <span className="text-span-bloc-2-style">
                        {external.username}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const RedeemCodeBloc = ({ games }) => {
  const [code, setCode] = useState("");
  const [game, setGame] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({ title: "", content: "" });

  const handleOptionChange = (e) => {
    setGame(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.get(process.env.REACT_APP_API_URL + "/api/serialcode", {
        withCredentials: true,
        params: { game, code },
      });
      setModalProps({
        title: "Success",
        content: "The code you entered was accepted.",
      });
    } catch (error) {
      setModalProps({
        title: "Error",
        content: "The code you entered wasn't accepted.",
      });
    }
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <div className="bloc bgc-3968 l-bloc " id="bloc-5">
        <div className="container bloc-lg">
          <div className="row">
            <div className="col text-start">
              <h2 className="mb-4 tc-3647 mb-lg-4">Redeem your serial codes</h2>
              <p className="text-sm-start text-start tc-3647 mb-2">
                Chose the game associated with your serial code:
              </p>
              <div className="row">
                <div className="col mb-4">
                  {games.map((game) => (
                    <div
                      key={game.code}
                      className="form-check form-check-inline radio-button-game-select"
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radioSetOne"
                        value={game.code}
                        onChange={handleOptionChange}
                      />
                      <label className="form-check-label">{game.name}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group mb-3">
                    <input
                      value={code}
                      className="form-control"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col">
                  <button
                    onClick={handleSubmit}
                    className="btn float-sm-none btn-c-521 btn-rd ps-lg-4 pe-lg-4 button-linkaccount"
                  >
                    Redeem
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal {...modalProps} onClose={() => setShowModal(false)} />
      )}
    </React.Fragment>
  );
};

export function MyAccount() {
  const { data } = useAuthData();
  const externals = data.externals;
  const games = data.games.filter((item) => item.installed);

  return (
    <React.Fragment>
      <HeaderBloc />
      <LinkBloc externals={externals} />
      {/*
      <RedeemCodeBloc games={games} />
      */}
    </React.Fragment>
  );
}
