import React from "react";

export function Footer() {
  return (
    <React.Fragment>
      <div className="bloc bgc-521 d-bloc" id="bloc-3">
        <div className="container bloc-lg">
          <div className="row">
            <div className="col-12 col-sm-4">
              <h4 className="text-center text-sm-start tc-7396">Company</h4>
              <a
                href="https://www.thirdverse.io"
                className="a-btn a-block footer-link"
                target="_blank"
                rel="noreferrer"
              >
                Thirdverse.io
              </a>
              <a
                href="https://www.thirdverse.io/en/contact.html"
                className="a-btn a-block footer-link"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </div>
            <div className="col-12 col-sm-4">
              <h4 className="text-center text-sm-start tc-7396 mt-5 mt-sm-0">
                Our Games
              </h4>
              <a
                href="https://playx8vr.com/en/"
                className="a-btn a-block footer-link"
                target="_blank"
                rel="noreferrer"
              >
                X8
              </a>
              <a
                href="https://altair-breaker.com"
                className="a-btn a-block footer-link"
                target="_blank"
                rel="noreferrer"
              >
                Altair Breaker
              </a>
              <a
                href="https://gargantuavr.com"
                className="a-btn a-block footer-link"
                target="_blank"
                rel="noreferrer"
              >
                Swords of Gargantua
              </a>
            </div>
            <div className="col-12 col-sm-4">
              <h4 className="text-center text-sm-start tc-7396 mt-5 mt-sm-0">
                Follow Us
              </h4>
              <a
                href="https://twitter.com/Thirdverse_US"
                className="a-btn a-block footer-link"
                target="_blank"
                rel="noreferrer"
              >
                Twitter (US)
              </a>
              <a
                href="https://twitter.com/Thirdverse_JP"
                className="a-btn a-block footer-link"
                target="_blank"
                rel="noreferrer"
              >
                Twitter (JP)
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bloc bgc-521 d-bloc" id="bloc-4">
        <div className="container bloc-no-padding-lg bloc-no-padding">
          <div className="row">
            <div className="col text-start">
              <p className="text-lg-center tc-4271 text-center">
                ©Thirdverse, Co., Ltd.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
