import React from "react";
import { Link } from "react-router-dom";
import { useAuthData } from "./../../authDataContext";

export function Header() {
  const { isAuthenticated } = useAuthData();

  return (
    <div className="bloc l-bloc none nav-padding " id="bloc-0">
      <div className="container bloc-no-padding-lg bloc-no-padding">
        <div className="row">
          <div className="col">
            <nav
              className="navbar navbar-light row navbar-expand-md"
              role="navigation"
            >
              <div className="container-fluid">
                <Link className="navbar-brand nav-logo" to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logoasset-1.svg`}
                    alt="logo"
                    width="120"
                    height="20"
                  />
                </Link>
                <button
                  id="nav-toggle"
                  type="button"
                  className="ui-navbar-toggler navbar-toggler border-0 p-0 ms-auto"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-30246"
                >
                  <span className="navbar-toggler-icon">
                    <svg height="32" viewBox="0 0 32 32" width="32">
                      <path
                        data-custom-classes=" menu-icon-stroke"
                        className="svg-menu-icon menu-icon-filled menu-icon-circles  menu-icon-stroke"
                        d="m5 14a3 3 0 1 1 -3 3 3 3 0 0 1 3-3zm11 0a3 3 0 1 1 -3 3 3 3 0 0 1 3-3zm11 0a3 3 0 1 1 -3 3 3 3 0 0 1 3-3z"
                      ></path>
                    </svg>
                  </span>
                </button>
                <div className="collapse navbar-collapse navbar-40281 navbar-9125 navbar-40222 navbar-30246">
                  <ul className="site-navigation nav navbar-nav ms-auto">
                    {/*
                    <li className="nav-item">
                      <Link to="/faq" className="nav-link a-btn ltc-3647">
                        FAQ
                      </Link>
                    </li>
                    */}
                    <li className="nav-item">
                      {isAuthenticated && (
                        <Link to="/signout" className="nav-link a-btn ltc-3647">
                          Sign Out
                        </Link>
                      )}
                    {/*
                        : (
                        <Link
                          to="/#sign-in"
                          className="nav-link a-btn ltc-3647"
                        >
                          Sign In
                        </Link>
                      )
                      }
                    */}
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
