import React from "react";
import { Modal } from "../Modal";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const LoginButton = ({ code, children }) => {
  const uri = `/sso/link/${code}?${window.location.hash.slice(1)}`;

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.getElementsByName(code)[0];
    form && form.submit();
  };

  return (
    <form method="post" name={code} action={`${REACT_APP_API_URL}${uri}`}>
      <a href="#" onClick={handleSubmit}>
        {children}
      </a>
    </form>
  );
};

export const LinkButton = ({ name, code, enabled, login, linkedLoginAccountsCount, linkedAdditionalAccountsCount }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const title = enabled
    ? `Unlink your ${name} account`
    : `Link your ${name} account`;
  const baseUri = enabled
    ? `/sso/unlink/${code}`
    : `/sso/link/${code}`;

  const isLastLoginAccount = login && enabled && linkedLoginAccountsCount === 1;
  const hasAdditionalAccounts = linkedAdditionalAccountsCount > 0;

  const uri = isLastLoginAccount ? `${baseUri}?force=1` : baseUri;
  const buttonClassSuffix = enabled ? "3647" : "521";

  const submitForm = isLastLoginAccount && hasAdditionalAccounts
    ? null
    : () => {
      const form = document.getElementsByName(code)[0];
      form && form.submit();
    };

  const content = isLastLoginAccount && hasAdditionalAccounts
    ? "Unlink all other linked accounts to unlink this one."
    : "Your all game data will be lost.  This operation is unable to cancel.  Will you continue?" ;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isLastLoginAccount) {
      setShowDialog(true);
    } else {
      submitForm && submitForm();
    }
  };

  return (
    <React.Fragment>
      <form method="post" name={code} action={`${REACT_APP_API_URL}${uri}`}>
        <button
          onClick={handleSubmit}
          className={`btn float-sm-none btn-rd ps-lg-4 pe-lg-4 button-linkaccount btn-c-${buttonClassSuffix}`}
        >
          {title}
        </button>
      </form>
      {showDialog && (
        <Modal
          title={title}
          content={content}
          ng="Cancel"
          onConfirm={submitForm}
          onClose={() => setShowDialog(false)}
        />
      )}
    </React.Fragment>
  );
};
